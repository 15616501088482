/* AddEstablishmentModal.css */

/* Estilo del contenedor del modal */
.add-establishment-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-establishment-modal-content {
  max-height: 90%;
  width: 50vw;
  overflow-y: auto;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 4rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid #4CAF50; /* Borde verde */
}

/* Scroll funcional */
.add-establishment-modal-content::-webkit-scrollbar {
  width: 10px;
}

.add-establishment-modal-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.add-establishment-modal-content::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

/* Botones del modal */
.add-establishment-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.add-establishment-actions .save-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  flex: 1;
}

.add-establishment-actions .cancel-button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  flex: 1;
}