/* FreePlanReminderBanner.css */

.free-plan-banner {
    background-color: #70e1ff; /* Fondo azul claro */
    border: 1px solid #70d3f5; /* Borde azul más claro */
    border-radius: 8px;
    margin: 20px 0;
    padding: 10px 20px;
    overflow: hidden;
    transition: max-height 100ms ease-in-out, opacity 100ms ease-in-out !important; /* Sincroniza ambas propiedades */
}

.banner-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    color: #040200; /* Texto oscuro */
}

.banner-header .banner-text {
    flex-grow: 1;
    margin-right: 10px;
}

.banner-header .toggle-button {
    background: none;
    border: none;
    font-size: 1rem;
    font-weight: bold;
    color: #0056b3; /* Azul */
    text-decoration: underline;
    cursor: pointer;
}

.banner-header .toggle-button:hover {
    color: #003a75; /* Azul más oscuro */
}

/* Animación del cuerpo del banner */
.banner-body {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 1s ease-in-out, opacity 1s ease-in-out;
}

.banner-body.expanded {
    max-height: 500px; /* Suficiente altura para el contenido */
    overflow: visible;
    opacity: 1;
}