.steps-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 1rem;
  background-color: #ffffff;
}

.container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  gap: 2rem;
}

.image-container {
  flex: 1;
  max-width: 600px;
  text-align: center;
}

.image-container img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.steps-content {
  flex: 1;
  max-width: 600px;
}

.steps-content h2 {
  font-size: 2rem;
  color: #003366;
  margin-bottom: 2rem;
}

.steps-content .highlight {
  color: #3F51B5;
}

.steps-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.step {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.step-number {
  font-size: 2rem;
  font-weight: bold;
  color: #c4c4c4;
  flex-shrink: 0;
}

.step-text h3 {
  font-size: 1.25rem;
  color: #003366;
  margin: 0;
}

.step-text p {
  font-size: 1rem;
  color: #555555;
  margin: 0;
  text-align: center;
}
