.signup-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.signup-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../public/newLoginBackground.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.signup-card {
  background-color: #fff;
  padding: 2rem 3rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  margin-top: 5rem !important;
  text-align: center;
}

.signup-card input {
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem; /* Reduce el espacio entre el input y el mensaje de error */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.signup-card input.input-error {
  border-color: red;
}

.signup-card button {
  background-color: #f5f5dc;
  color: #333;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
}

.signup-card button:hover {
  background-color: #e6e6d4;
}

.signup-card p {
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: #666;
}

.signup-card p a {
  color: #333;
  text-decoration: none;
  font-weight: bold;
}

.signup-card p a:hover {
  text-decoration: underline;
}

.error {
  font-size: 0.85rem;
  margin-top: -.5rem!important; /* Ajusta para acercar el mensaje al input */
  margin-bottom: 1rem;
  text-align: center; /* Para que el texto de error se alinee a la izquierda */
  color: red !important;
}

.error-text {
  font-size: 0.875rem;
}

.password-container {
  position: relative;
}

.password-container input {
  width: 100%; /* Asegura que el input use todo el espacio disponible */
  padding-right: 2.5rem; /* Asegura que el texto no pase por debajo del ícono */
}

.toggle-password {
  position: absolute;
  top: 50%; /* Alinea verticalmente */
  right: 10px; /* Margen derecho dentro del input */
  transform: translateY(-50%); /* Ajusta la posición vertical */
  cursor: pointer;
  width: 24px; /* Ajusta el tamaño del icono */
  height: 24px;
}