.admin-container {
    margin-top: 4rem !important;
}

.pagination-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fill-available;
    margin-bottom: 2rem;
}

.pagination-section button {
    background-color: #0056b3;
}

.admin-container h1 {
    color: #0056b3;
}

.table-hover tbody tr:hover {
    background-color: #f1f1f1;
}

.pagination-section button {
    border-radius: 20px;
}

.pagination-section button {
    border-radius: 20px;
}

.pagination-section .btn-primary {
    background-color: #0056b3;
    border-color: #0056b3;
}

.pagination-section .btn-outline-secondary:hover {
    background-color: #e9ecef;
}

.segment-button{
    background-color:lightgray !important;
    color: black;
}

.table-responsive{
    width: 85rem;
}

.auto-resize-modal .modal-dialog {
    max-width: 90%; /* Ajusta el ancho máximo del modal */
    margin: auto;
}

.auto-resize-modal .modal-content {
    overflow-y: auto; /* Habilita el scroll vertical si el contenido es demasiado largo */
    
}