.profile-content {
  display: flex;
  margin-top: 5rem; /* Ajusta según tu header */
  margin-left: 4rem;
  transition: margin-left 0.3s ease;
}

/* Asegúrate de que todo el contenido esté debajo del header */
.profile-page-container {
  display: flex;
  margin-top: 5rem; /* Altura del header */
  margin-left: 4rem;
  transition: margin-left 0.3s ease; /* Movimiento suave del contenedor principal */
}

/* Estilo para la sidebar de perfil */
.profile-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 230px;
  transition: margin-left 0.3s ease; /* Movimiento suave del contenedor principal */
  background-color: white;
  padding: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-left: 210px; /* Espacio reservado para el sidebar */
}

.sidebar.collapsed ~ .profile-sidebar {
  margin-left: 4rem; /* Ajusta el contenido cuando el sidebar está colapsado */
}


.profile-sidebar h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.profile-sidebar p {
  font-size: 1rem;
  color: #555;
  text-align: center;
}

/* Sección de detalles del perfil */
.profile-details {
  width:fit-content;
  position: center;
  max-width: 15rem;
  flex-grow: 1;
  padding: 1rem;
  transition: margin-left 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-left: 4rem; /* Espacio para el sidebar expandido */
  align-items: center;
}

.sidebar.collapsed ~ .profile-details {
  margin-left: 50px; /* Ajuste cuando el sidebar está colapsado */
}

.profile-details button {
  margin-top: 1rem;
  width: 100%;
}

/* Estilos de edición */
.profile-form {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  padding: 1rem !important;
}

.forms {
  padding: 0.5rem !important;
  margin-bottom: 0 !important;
}

.forms label {
  display: block;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  text-align: left !important;
}

.forms input {
  width: 11.5rem;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  background-color: #007bff;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
}

button:hover {
  background-color: #0056b3;
}

/* Información de perfil */
.profile-info {
  padding: 1rem;
}


.profile-info p {
  font-size: 1.1rem;
  margin-bottom: 0.5rem !important;
}

.profile-info button {
  margin-top: 1rem;
  width: 100%;
}

/* Cuando el sidebar esté colapsado */
.profile-sidebar.collapsed {
  width: 50px;
  z-index: 101; /* Asegura que esté encima del contenido */
}

.dashboard-container {
  display: flex;
}

.tabs {
  display: flex;
  margin-bottom: 1rem;
}

.tabs a {
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  text-decoration: none;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.active-tab {
  background-color: #4CAF50;
  color: white;
}

.tab-content {
  padding: 1rem;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Estilo para alinear las secciones del establecimiento y las visitas */
.details-and-visits {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.details-container, .visits-container {
  flex: 1;
}

.visits-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 20px;
  /* Elimina cualquier fondo gris o sombra de modal que no quieras */
}

.modal-content {
  background-color: transparent; /* Elimina fondo gris del modal */
  box-shadow: none; /* Elimina la sombra si hay */
}

.details-and-visits > div {
  flex: 1;
}

/* Estilos para la sección de detalles del establecimiento */
.establishment-details-section {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%; /* Asegura que el contenedor se ajuste correctamente */
  box-sizing: border-box; /* Incluye el padding en el ancho total */
}

.establishment-details-section h2 {
  margin-bottom: 10px;
}

/* Estilos para el botón de configurar visitas periódicas */
button.configure-visits-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

button.configure-visits-button:hover {
  background-color: #45a049;
}

/* Estilos para la sección de la próxima visita */
.visits-info-section {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.visits-info-section h2 {
  margin-bottom: 10px;
}

.visits-info-section .days-remaining {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  background-color: #e0f7fa;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  text-align: center;
}

.information{
  margin-bottom: 1rem;
}

.profile-picture-container {
  position: relative;
  display: inline-block;
  border: #333;
  border-style: solid;
  border-width: 1px;
  padding: 0.5rem;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.profile-picture-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 150px; /* Asegúrate de que coincida con el tamaño de la imagen */
  height: 150px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
  margin: 0.5rem;
}

.profile-picture-container:hover .profile-picture-overlay {
  opacity: 1;
}

.signature-section {
  margin-top: 5rem;
  text-align: center;
  align-content: center;
}

.signature-container {
  position: relative; /* Añadir esta línea */
  display: block;
  align-self: center !important;
  align-content: center !important;
  height: 200px;
  max-width: 375px;
  max-height: 105px;
  border: 1px solid #333;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
}

.signature-image,
.company-logo-image {
  max-width: 150px;
  max-height: 100%;
}

.signature-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Cambiar a 100% */
  height: 100%; /* Cambiar a 100% */
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.signature-container:hover .signature-overlay {
  opacity: 1;
}

.company-logo-section {
  margin-top: 5rem;
  text-align: center;
}

.company-logo-container {
  position: relative; /* Añadir esta línea */
  display: block;
  align-self: center !important;
  align-content: center !important;
  height: 200px;
  max-width: 375px;
  max-height: 105px;
  border: 1px solid #333;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
}


.company-logo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Cambiar a 100% */
  height: 100%; /* Cambiar a 100% */
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.company-logo-container:hover .company-logo-overlay {
  opacity: 1;
}

.profile-main {
  flex-grow: 1;
  padding: 1rem;
}

.profile-main h2,
.profile-main p {
  text-align: left;
}

.profile-main h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.profile-main p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1rem;
}

.section-title {
  margin-top: 0;
  margin-bottom: 0.5rem;
  text-align: left !important;
  align-self: flex-start;
  
  color:black
}

.error-message {
  color: red;
  margin-top: 0.5rem;
  text-align: left;
}