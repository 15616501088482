/* EstablishmentWorkingHours.css */

/* Estilo general del modal */
.working-hours-modal {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  h2 {
    margin-bottom: 10px;
    text-align: center;
  }
  
  /* Estilo de cada fila de día */
  .day-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-top: 5px;
    margin-top: 5px;
  }
  
  .time-slots {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }
  
  /* Estilo para las franjas horarias */
  .time-slot {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .time-slot input[type="time"] {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
  }
  
  .time-slot span {
    margin: 0 5px;
  }
  
  .time-slot button {
    background: none;
    border: none;
    color: #ff4d4d;
    cursor: pointer;
    font-size: 16px;
    padding: 0;
  }
  
  /* Botón de agregar franja horaria */
  .time-slots button {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 5px;
    align-self: start;
  }
  
  /* Estilo de los botones del modal */
  .modal-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .save-button {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    flex: 1;
    margin-top:5px !important;
    height:40px !important;
  }
  
  .cancel-button {
    margin-top: 5px;
    height:40px !important;
    background-color: #f44336 !important;
    color: white !important;
    border: none !important;
    border-radius: 5px !important;
    padding: 10px 15px !important;
    cursor: pointer !important;
    flex: 1 !important;
  }
  .working-hours-container{
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    width: 25rem;
  }
  
  /* Botón de editar horarios */
  .edit-working-hours-button {
    background-color: #6200ea;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    justify-self: center !important;
    align-items: center !important;
    align-content: center !important;
    align-self: center !important;
    display: flex !important;
  }
  
  .edit-working-hours-button:hover {
    background-color: #3700b3;
  }

  /* Estilo general del modal */
.working-hours-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Contenido del modal con scroll */
.working-hours-modal-content {
  margin-top: 4rem;
  max-height: 90vh;
  width: 50vw;
  overflow-y: auto;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid #4CAF50; /* Borde verde */
}

h2 {
  margin-bottom: 10px;
  text-align: center;
}

/* Mantener espacio entre elementos */
.day-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-top: 5px;
}

.time-slots {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

/* Scroll funcional */
.working-hours-modal-content::-webkit-scrollbar {
  width: 10px;
}

.working-hours-modal-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.working-hours-modal-content::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.delete-icon {
  width: 20px;
  height: 20px;
}

.remove-time-slot {
  background: none !important;
  border: 1px solid #ccc !important;
  cursor: pointer !important;
}