/* CalendarView.css */
.calendar-container {
    margin: 20px;
    margin-left: 30px;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 40rem;
    height: 35rem;
  }
  .fc-media-screen {
    border: 1px solid #ddd;
    height: 97.5%;
  }
  
  .fc-event {
    display: block;
    width: 100%; /* Hace que los eventos ocupen todo el ancho del día */
    margin: 0; /* Elimina cualquier margen alrededor del evento */
    box-sizing: border-box; /* Asegura que los bordes y padding se incluyan en el ancho total */
  }
  /* Ajustes responsivos */
  @media (max-width: 768px) {
    .calendar-container {
      margin: 10px;
      padding: 5px;
    }
  }
  