/* Estilo general del modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fondo oscuro */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Asegura que esté por encima de todo */
}

.modal-content {
  background: #ffffff;
  width: 90%;
  max-width: 500px;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s ease-in-out;
  position: relative;
}

/* Animación de entrada */
@keyframes slideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Botón de cerrar */
.close-modal-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: #555;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-modal-btn:hover {
  color: #ff5252;
}

/* Botón principal para abrir el modal */
.periodic-visit-button {
  background-color: #3f51b5;
  color: #ffffff;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.periodic-visit-button:hover {
  background-color: #303f9f;
}

/* Contenido dentro del modal */
.modal-header {
  font-size: 1.5rem;
  font-weight: bold;
  color: #3f51b5;
  margin-bottom: 1rem;
  text-align: center;
}

.modal-body {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.modal-actions button {
  padding: 0.5rem 1.2rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-actions .save-btn {
  background-color: #4caf50;
  color: #ffffff;
}

.modal-actions .save-btn:hover {
  background-color: #388e3c;
}

.modal-actions .cancel-btn {
  background-color: #f44336;
  color: #ffffff;
}

.modal-actions .cancel-btn:hover {
  background-color: #d32f2f;
}

/* Estilo para la sección de próxima visita */
.next-visit-info {
  margin-top: 2rem;
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fafafa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.next-visit-info h3 {
  font-size: 1.25rem;
  color: #3f51b5;
  margin-bottom: 1rem;
}

.next-visit-info p {
  margin: 0.5rem 0;
  color: #555;
}

.days-remaining {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.days-remaining p {
  font-size: 1rem;
  color: #555;
}

.days-box {
  background-color: #e0f7fa;
  color: #00796b;
  font-size: 2.5rem;
  font-weight: bold;
  padding: 1rem 2rem;
  border-radius: 8px;
  margin-top: 0.5rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
}

/* Responsive */
@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    padding: 1.5rem;
  }

  .days-box {
    font-size: 2rem;
    padding: 0.8rem 1.5rem;
  }

  .periodic-visit-button {
    font-size: 0.9rem;
    padding: 0.6rem 1rem;
  }
}
