@import 'leaflet/dist/leaflet.css';
.dashboard-container {
  display: flex;
}

.tabs {
  display: flex;
  margin-bottom: 1rem;
}

.tabs a {
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  text-decoration: none;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.active-tab {
  background-color: #4CAF50;
  color: white;
}

.tab-content {
  padding: 1rem;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Estilo para alinear las secciones del establecimiento y las visitas */
.details-and-visits {
  display: flex;
  justify-content: left;
  gap: 20px;
  width:fit-content;
  height: fit-content;
}

.details-container, .visits-container {
  flex: 1;
}

.visits-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 20px;
  max-width: 400px;
  /* Elimina cualquier fondo gris o sombra de modal que no quieras */
}

.rate-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 20px;
  max-width: 400px;
  /* Elimina cualquier fondo gris o sombra de modal que no quieras */
}

.accordion-toggle {
  margin-bottom: 1rem;
}

.modal-content {
  background-color: transparent; /* Elimina fondo gris del modal */
  box-shadow: none; /* Elimina la sombra si hay */
  
}

.details-and-visits > div {
  flex: 1;
}

/* Estilos para la sección de detalles del establecimiento */
.establishment-details-section {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%; /* Asegura que el contenedor se ajuste correctamente */
  box-sizing: border-box; /* Incluye el padding en el ancho total */
}

.establishment-details-section h2 {
  margin-bottom: 10px;
}

/* Estilos para el botón de configurar visitas periódicas */
button.configure-visits-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

button.configure-visits-button:hover {
  background-color: #45a049;
}

/* Estilos para la sección de la próxima visita */
.visits-info-section {
  background-color: #f9f9f9;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  align-content: center;
  max-width: 280px;
  max-height: 360px;
}

.visits-info-section h2 {
  margin-bottom: 10px;
}

.visits-info-section .days-remaining {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  background-color: #e0f7fa;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  text-align: center;
}


.rate-display {
  display: flex;
  align-items: center;
}

.show-rate-icon {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.edit-rate-button, .save-rate-button {
  margin-left: 15px;
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-rate-button:hover, .save-rate-button:hover {
  background-color: #45a049;
}

.rate-input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.update-rate-button,
.save-rate-button {
  background-color: #4CAF50;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.update-rate-button:hover,
.save-rate-button:hover {
  background-color: #45a049;
}

input {
  font-size: 20px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  text-align: center;
}

.amount-container{
  border: 2px dashed #333;
  border-radius: 8px;
  padding: 10px;
}

.google-maps-button{
  background-color: #4CAF50 !important;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin:5px;
  justify-self: center;
}

.no-coordinates-message {
  background-color: #e0f7fa; /* Fondo celestito */
  border-radius: 5px; /* Borde redondeado */
  padding: 10px; /* Espaciado interno */
  margin: 10px 0; /* Espaciado hacia arriba y abajo */
  color: #00796b; /* Color del texto */
  text-align: center; /* Centrar el texto */
}

.primary-contact-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact-links {
  display: flex;
  justify-content: center;
  gap: 20px; /* Espacio entre los enlaces */
  margin-top: 10px;
}

.contact-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  background-color: #e0f7fa;
  padding: 8px 12px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s;
}

.contact-link:hover {
  background-color: #b2ebf2;
  transform: translateY(-3px); /* Efecto de elevación */
}

.contact-icon {
  margin-right: 8px;
  font-size: 20px;
  color: #00796b;
}

.contact-link span {
  font-weight: bold;
  font-size: 14px;
  color: #00796b;
}

/* Botón de desplegar detalles */
.toggle-details-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-details-button:hover {
  background-color: #45a049;
}

/* Sección de detalles del establecimiento */
.details-section {
  background-color: #f9f9f9;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.details-section p {
  margin: 5px 0;
}

.edit-establishment-button {
  margin: 1rem 0 1rem 0;
}

.leaflet-container{
  z-index: 100!important;
}