.footer {
    background: linear-gradient(to top,rgb(63, 134, 181), #eaf2f2);
    /* De color sólido a transparente */
    color: #434141;
    padding: 1rem 1rem;
    text-align: center;
    position: relative; /* Asegura que el footer se comporte correctamente */
    z-index: 1; /* Asegura que se coloque sobre otros elementos si es necesario */
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer p {
    color: #434141 !important;
}

.footer-logo {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.footer-logo-image {
    width: 40px;
    height: 40px;
}

.footer-logo-text {
    font-size: 2rem;
    font-weight: bold;
}

.footer-text {
    color: #000;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.footer-socials {
    display: flex;
    gap: 1rem;
    cursor: pointer !important;

}

.social-icon {
    font-size: 1.5rem;
    color: #ffffff;
    transition: transform 0.3s ease, color 0.3s ease;
    cursor: pointer !important;

}

.social-icon:hover {
    color: #000000;
    /* Color al pasar el mouse */
    transform: scale(1.2);
    /* Escala al pasar el mouse */
    cursor: pointer !important;

    /* setear el mouse a un pointer */
}