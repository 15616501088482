.working-hours-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  width: fit-content !important;

}


.working-hours-container h2 {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  color: #4A4A4A;
}

.clock-icon {
  color: #007bff;
}

.days-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.day-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  border-bottom: 1px solid #eee;
}

.day-row.today-row {
  background-color: #e8f5e9; /* Verde claro */
  color: #388e3c; /* Texto verde */
  font-weight: bold;
  border-radius: 4px;
}

.day-name {
  font-weight: bold;
  color: #333;
}

.hours {
  color: #555;
}

.closed {
  color: #999;
}

.no-schedule-container {
  text-align: center;
  margin-top: 20px;
  width: 30rem;
}

.no-schedule-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.no-schedule-message {
  font-size: 16px;
  margin: 10px 0;
  color: #555;
}

.go-to-establishment-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.go-to-establishment-button:hover {
  background-color: #0056b3;
}