/* Header.css */

.main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  /* Ajusta según sea necesario */
  background-color: #333;
  color: white;
  z-index: 10000;
  /* Asegura que esté por encima de otros elementos */
  display: flex;
  justify-content: space-between;
  /* Coloca los elementos a los extremos */
  align-items: center;
  /* Centra verticalmente los elementos */
  padding: 0 20px;
  /* Añade un padding para que los elementos no queden pegados a los bordes */
}

/* Estilos para el logo y el título */
.header-left h1 {
  margin: 0;
  font-size: 1.5rem;
}

/* Añadimos flex para alinear mejor los elementos dentro del header */
.header-left {
  display: flex;
  align-items: center;
}

/* Espacio alrededor del texto "BioSafeApp" */
.header-left h1 {
  margin-left: 10px;
  /* Espacio entre el borde y el texto */
}

/* El contenedor derecho para el botón hamburger */
.header-right {
  position: relative;
  display: flex;
  align-items: center;
  /* Centrar verticalmente */
  justify-content: flex-end;
  /* Mover el contenido hacia la derecha */
  flex: 1;
  /* Asegurarte de que este contenedor ocupe el máximo espacio posible */
}

/* Estilos para el botón del menú tipo "hamburger" */
.hamburger-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 2rem;
}

.hamburger-button img {
  width: 30px;
  height: 30px;
}

/* Dropdown menu para las opciones al hacer clic en el hamburger */
.hamburguer-dropdown-menu {
  background-color: white;
  color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  border-radius: 5px;
  position: absolute;
  right: 10px;
  /* Cambia de 0 a 10px para darle margen desde el borde derecho */
  top: 100%;
  z-index: 200;
  /* Asegúrate de que esté sobre el contenido */
  width: 13rem;
  /* Ajusta el ancho según sea necesario */
  margin-right: 1rem;
  /* Ajusta el margen derecho según sea necesario */
}

/* Dropdown menu visible cuando se active */
.dropdown-menu.active {
  display: flex;
}

/* Estilos para los ítems del menú desplegable */
.dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  color: #333;
  font-family: "Roboto", sans-serif; 
  background: none;
  transition: background-color 0.3s;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

/* Icono que acompaña al dropdown-item */
.dropdown-icon {
  margin-right: 8px;
  height: 20px;
  width: 20px;
}

.add-visit-button {
  transition: background-color 0.2s ease-in-out;
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: #333;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  margin: 4px 2px;
  cursor: pointer;
  margin-left: 2rem;
  border-radius: 8px;
}

.add-visit-button:hover {
  background-color: #107f13;
  transition: background-color 0.2s ease-in-out;
}