/* === Overlay del Modal === */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden; /* Evita scroll en el fondo */
}

/* === Contenedor Principal === */
.pdf-view-modal-container {
  background-color: #ffffff;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: relative;
  margin-top: 4rem;
  border: 2px solid #4CAF50;
}

/* === Encabezado === */
.pdf-view-modal-header {
  background-color: #4CAF50;
  color: #ffffff;
  padding: 15px;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  border-radius: 8px 8px 0 0;
  position: relative;
}

.pdf-view-modal-header h2 {
  margin: 0;
  color: #ddd;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  background-color: #c0392b;
}

/* === Cuerpo del Modal === */
.pdf-view-modal-body {
  padding: 20px;
  background-color: #fbe8cd;
  flex: 1;
  overflow-y: auto;
}

.pdf-view-modal-body p {
  margin-bottom: 1.5rem;
  text-align: center;
  color: #333;
}

/* === Tabla de Datos === */
.form-fields {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.form-fields th,
.form-fields td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  color: #333 !important;
}

.form-fields th {
  background-color: #4CAF50;
  color: white;
}

.form-fields tr:hover {
  background-color: #f1f1f1;
}

/* === Pie del Modal === */
.pdf-view-modal-footer {
  padding: 15px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: flex-end;
  border-radius: 0 0 8px 8px;
}

.download-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.download-button:hover {
  background-color: #388E3C;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}
