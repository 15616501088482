.paginated-form {
  width: 35rem !important;
  height: 80vh;
  margin: 0 auto;
  padding: 1rem;
  background-color: #def1f4;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  /* Permite el scroll en el modal */
  position: relative;
  display: flex;
  flex-direction: column;
}

.modal-content {
  overflow-y: auto;
  /* Scroll interno en el modal */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.form-fields {
  padding: 0.5rem;
  flex-grow: 1;
  overflow-y: auto;
  border-radius: 10px;
}

.form-group {
  margin-bottom: 1.5rem;
  background-color: #ffffff;
  /* Color de fondo */
  padding: 1rem;
  border-radius: 12px;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  text-align: center;
}

.radio-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio-group label {
  margin-left: 0.5rem;
  margin-right: 1rem;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}

.pagination-container button {
  margin: 0 0.25rem;
  padding: 0.5rem 1rem;
  border: none;
  background-color: #6bb7fa;
  color: rgb(0, 0, 0);
  cursor: pointer;
}

.pagination-container button.active {
  background-color: #ffffff;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.form-group.error {
  border: 1px solid red;
}

.error-text {
  font-size: 0.875rem;
  margin-top: 0.25rem;
  color: red;
  /* Color de la alerta de error */
}

body.modal-open {
  overflow: hidden;
}

.save-button {
  margin: 0.5rem 0;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #007bff;
  background-color: white;
  border: 2px solid #007bff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  max-height: 4rem !important;
}

.save-button:hover {
  background-color: #b8babc;
  color: #007bff;
}

/* PaginatedForm.css */
.options-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Dos columnas de igual tamaño */
  gap: 10px;
  /* Espacio entre las opciones */
  align-items: center;
  /* Alineación vertical de las opciones */
}