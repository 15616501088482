/* === Overlay del Modal === */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* === Contenedor Principal === */
.form-modal-container {
  background-color: #ffffff;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  text-align: center;
  height: fit-content;
  margin-top:6rem;
  margin-bottom: 1rem;
}

/* === Encabezado === */
.modal-header {
  background-color: #4CAF50;
  color: white;
  padding: 15px;
  font-size: 1.3rem;
  font-weight: bold;
}

.form-header {
  background-color: #4CAF50 !important;
  justify-content: center !important;
}

h3 {
  text-align: center !important;
  margin: 0;
}
/* === Cuerpo === */
.modal-body {
  padding: 20px;
  overflow-y: auto;
}

/* === Botón de Cerrar === */
.modal-footer {
  padding: 10px;
  display: flex;
  justify-content: center;
  background-color: #f1f1f1;
}

.close-modal-btn {
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.close-modal-btn:hover {
  background-color: #c0392b;
}

/* === Botones de Opciones === */
.modal-buttons-grid {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-button-row {
  display: flex;
  gap: 10px;
}

.modal-button {
  flex: 1;
  padding: 10px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-button:hover {
  background-color: #0056b3;
}