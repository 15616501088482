.dashboard-container {
  display: flex;
  padding-top: 60px;
  margin-left: 2rem !important;
}

.dashboard-content {
  flex-grow: 1;
  padding: 2rem;
  margin-left: 210px;
}

.custom-forms-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1.5rem;
}

.custom-forms-table th,
.custom-forms-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.custom-forms-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.custom-forms-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.custom-forms-table tr:hover {
  background-color: #f1f1f1;
}

.form-row {
  transition: background-color 0.2s ease;
}

.add-form-button {
  margin-bottom: 20px;
}

/* Unificar tamaño de los botones */
.button {
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 3px;
  cursor: pointer;
  min-width: 120px; /* Ancho mínimo unificado */
  text-align: center;
}

.primary-button,
.edit-button,
.delete-button {
  padding: 8px 12px;
  font-size: 16px;
  min-width: 120px; /* Ancho mínimo unificado */
}

.primary-button {
  background-color: #28a745;
  color: white;
  border: none;
}

.primary-button:hover {
  background-color: #218838;
}

.edit-button {
  background-color: #007bff;
  color: white;
  border: none;
  margin-right: 5px;
}

.edit-button:hover {
  background-color: #0056b3;
}

.delete-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 12px; /* Asegúrate de que el padding sea igual a los otros botones */
  font-size: 16px;   /* Igual que los otros botones */
  min-width: 120px;  /* Igualar el ancho mínimo */
  cursor: pointer;
  border-radius: 3px;
  max-height: 40px !important;
}

.delete-button:hover {
  background-color: #c82333;
}

.dropdown-rubros {
  position: absolute;
  top: 100%; /* Ajusta la posición para que aparezca justo debajo del botón */
  left: 0;
  transform: translate(100%, 0%); /* Centra el dropdown con respecto al botón */
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  z-index: 10; /* Asegura que el dropdown esté encima de otros elementos */
  width: max-content;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Agrega una sombra para mayor visibilidad */
}

.dropdown-rubros label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dropdown-rubros .primary-button {
  margin-top: 10px;
}