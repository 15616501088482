.settings {
  width:fit-content;
  position: center;
  flex-grow: 1;
  padding: 1rem;
  transition: margin-left 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  align-items: center;
}

.settings-content {
  display: flex;
  margin-top: 5rem; /* Ajusta según tu header */
  margin-left: 4rem;
  transition: margin-left 0.3s ease;
}

/* Asegúrate de que todo el contenido esté debajo del header */
.settings-page-container {
  display: flex;
  margin-top: 5rem; /* Altura del header */
  margin-left: 4rem;
  transition: margin-left 0.3s ease; /* Movimiento suave del contenedor principal */
}

/* Estilo para la sidebar de configuraciones */
.settings-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30rem;
  transition: margin-left 0.3s ease; /* Movimiento suave del contenedor principal */
  background-color: white;
  padding: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-left: 210px; /* Espacio reservado para el sidebar */
}

.sidebar.collapsed ~ .settings-sidebar {
  margin-left: 4rem; /* Ajusta el contenido cuando el sidebar está colapsado */
}


.settings-sidebar h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.settings-sidebar p {
  font-size: 1rem;
  color: #555;
  text-align: center;
}

.sidebar.collapsed ~ .settings-details {
  margin-left: 50px; /* Ajuste cuando el sidebar está colapsado */
}

.settings-details button {
  margin-top: 1rem;
  width: 100%;
}

/* Estilos de edición */
.settings-form {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  padding: 1rem !important;
}

.forms {
  padding: 0.5rem !important;
  margin-bottom: 0 !important;
}

.forms label {
  display: block;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  text-align: left !important;
}

.forms input {
  width: 5rem;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  background-color: #8ac3ff;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 0;
  margin-left: 5px;
}

button:hover {
  background-color: #0375ee;
  color: white;
}

/* Cuando el sidebar esté colapsado */
.settings-sidebar.collapsed {
  width: 50px;
  z-index: 101; /* Asegura que esté encima del contenido */
}

.modal-content {
  background-color: transparent; /* Elimina fondo gris del modal */
  box-shadow: none; /* Elimina la sombra si hay */
}

.information{
  margin-bottom: 1rem;
}

.settings-main {
  flex-grow: 1;
  padding: 1rem;
}

.settings-main h2,
.settings-main p {
  text-align: left;
}

.settings-main h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.settings-main p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1rem;
}

.section-title {
  margin-top: 0;
  margin-bottom: 0.5rem;
  text-align: left !important;
  align-self: flex-start;
  
  color:black
}

.error-message {
  color: red;
  margin-top: 0.5rem;
  text-align: left;
}

.rubros-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.rubro-item {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 5px;
}

.rubros-list {
  max-height: 300px; /* Establece una altura máxima */
  overflow-y: auto;  /* Habilita el scroll vertical */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.add-rubro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}