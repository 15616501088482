/* === Overlay del Modal === */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden; /* Evita scroll en el overlay */
}

/* === Contenedor Principal === */
.visit-details-modal-container {
  background-color: #ffffff;
  border-radius: 8px;
  width: 90%;
  max-width: 700px;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  padding: 1rem !important;
  border: 2px solid #4CAF50;
  margin-top: 4rem;
  position: relative;
}

/* === Encabezado del Modal === */
.visit-details-header {
  background-color: #4CAF50;
  color: white !important;
  padding: 15px;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  border-radius: 8px 8px 0 0;
}

.visit-details-header h2 {
  margin: 0;
  color: #ffffff;
  font-weight: bold;
}

.close-button {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  background-color: #e74c3c !important;
  color: white !important;
  border: none !important;
  border-radius: 8px !important;
  width: fit-content !important;
  height: 30px !important;
  font-size: 1.2rem !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 10 !important;
  margin-top: 1rem;
  margin-right: 0.5rem;
}

.close-button:hover {
  background-color: #c0392b  !important;
}

/* === Cuerpo del Modal === */
.visit-details-body {
  padding: 20px;
  background-color: #fbe8cd;
  border-radius: 8px;
  overflow-y: auto;
  flex: 1;
  justify-items: center !important;
  justify-content: center !important;
}

.visit-details-body p,
.visit-details-body label,
.visit-notes {
  text-align: center;
  width: 100%;
  margin-bottom: 1rem; /* Espaciado entre elementos */
}

.visit-details-body label {
  display: block;
  margin-top: 1rem;
}

.link {
  color: #007bff !important;
  text-decoration: underline !important;
  display: block;
  text-align: center;
  margin-bottom: 1rem;
}

.link:hover {
  color: #0056b3 !important;
}

/* === Sección de Notas === */
.visit-notes {
  margin-top: 20px;
  padding: 15px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
}

.visit-notes h3 {
  margin-bottom: 0.5rem;
}

.visit-notes button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
}

.visit-notes button:hover {
  background-color: #0056b3;
}

.visit-notes div {
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* === Tabla de Formularios Adjuntos === */
.attached-forms-container {
  margin: 20px 0;
  padding: 10px;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
}

.styled-table th,
.styled-table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.styled-table th {
  background-color: #f4f4f4;
}

.styled-table td img {
  cursor: pointer;
}

/* === Footer del Modal === */
.visit-details-footer {
  display: flex;
  justify-content: center;
  border-radius: 0 0 8px 8px;
}

.save-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.save-button:hover {
  background-color: #388E3C;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.modal-button {
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.modal-button:hover {
  opacity: 0.9;
}
