.modal-content label {
  display: block;
  margin-bottom: 10px;
}

.modal-content select {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.modal-buttons button {
  padding: 8px 12px;
}

.add-form-modal-content {
  padding: 20px;
  position: relative;
  background: white;
  border-radius: 8px;
  width: 90%;
  max-height:fit-content;
  max-width: 600px;
  margin-top: -2.5rem !important;
  /* Margen superior */
  height: 92% !important;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* Ocultamos desbordamientos */
  border: 2px solid #4CAF50;
}

.add-form-modal-content{
  max-height: 15rem;
}

.add-form-modal-content h2{
  color:black;
}