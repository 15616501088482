/* === Overlay del Modal === */
.periodic-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

/* === Contenedor Principal === */
.periodic-visit-modal-container {
  background-color: #ffffff;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  border: 2px solid #4CAF50;
  position: relative;
}

/* === Encabezado del Modal === */
.periodic-visit-header {
  background-color: #4CAF50;
  color: white;
  padding: 15px;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  border-radius: 8px 8px 0 0;
  position: relative;
}

.periodic-visit-header h2 {
  margin: 0;
  color:#f1f1f1
}

.periodic-visit-header .close-button {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  background-color: #e74c3c !important;
  color: white !important;
  border: none !important;
  border-radius: 8px !important;
  width: fit-content !important;
  height: 30px !important;
  font-size: 1.2rem !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 0.5rem;
}

.periodic-visit-header .close-button:hover {
  background-color: #c0392b;
}

/* === Cuerpo del Modal === */
.periodic-visit-body {
  padding: 20px;
  background-color: #fbe8cd;
  flex: 1;
  overflow-y: auto;
}

.periodic-visit-body p {
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

.periodic-visit-body a {
  color: #007bff;
  text-decoration: underline;
  display: block;
  text-align: center;
  margin: 10px 0;
}

.periodic-visit-body a:hover {
  color: #0056b3;
}

/* === Información de Próxima Visita === */
.next-visit-info {
  margin-top: 10px !important;
  text-align: center;
}

.days-remaining h2 {
  font-size: 18px;
  color: #555;
  margin-bottom: 10px;
}

.next-visit-info h3 {
  font-weight: bold;
}

.days-box {
  display: inline-block;
  background-color: #fae093 !important;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 10px;
}

/* === Footer del Modal === */
.periodic-visit-footer {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 15px;
  background-color: #f1f1f1;
  border-radius: 0 0 8px 8px;
}

.periodic-visit-footer .close-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.periodic-visit-footer .close-button:hover {
  background-color: #c82333;
}
