/* Estilos para la sección */
.why-choose-us {
  padding: 4rem 1rem;
  background-color: #f9f9f9;
  text-align: center;
}

.why-choose-us .container {
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
}

.why-choose-us h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  width: fill-available;
  color: #003366;
}

.why-choose-us .highlight {
  color: #3F51B5;
}

.why-choose-us p {
  font-size: 1.25rem;
  color: #555555;
  width: fill-available;
  margin-bottom: 3rem;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  /* Espaciado entre las tarjetas */
  margin-top: 2rem;
}

.feature-item {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  /* Usamos flexbox */
  flex-direction: column;
  /* Apilamos los elementos verticalmente */
  justify-content: flex-start;
  /* Alinea los elementos al inicio vertical */
  align-items: center;
  /* Centra los elementos horizontalmente */
  gap: 0.5rem;
  /* Espaciado entre el ícono, título y descripción */
}

.feature-item .icon {
  width: 40px; /* Ajusta el tamaño del ícono */
  height: auto; /* Mantén las proporciones */
  margin-bottom: 1rem; /* Espaciado entre el ícono y el título */
}


.feature-item h3 {
  font-size: 1.25rem;
  color: #003366;
  margin-bottom: 0.5rem;
}

.feature-item p {
  font-size: 1rem;
  color: #555555;
  margin: 0;
  /* Eliminamos márgenes extra */
  text-align: center;
}