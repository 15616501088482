.establishments-list-container table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .establishments-list-container th, 
  .establishments-list-container td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .establishments-list-container th {
    background-color: #4fb660;
    color: white;
    font-weight: bold;
    text-align: left;
  }
  
  .establishments-list-container tr:hover {
    background-color: #f1f1f1;
  }
  
  .establishments-list-container td img {
    width: 20px;
    height: 20px;
  }