/* === Overlay del Modal === */
.receipt-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto; /* Permite scroll si el contenido es extenso */
}

/* === Contenedor Principal del Modal === */
.receipt-modal-container {
  background-color: #ffffff;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 90%;
  margin-top: 4rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  border: 2px solid #4CAF50;
  display: flex;
  flex-direction: column;
  position: relative;
}

/* === Encabezado del Modal === */
.receipt-modal-header {
  background-color: #4CAF50;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  font-size: 1.5rem;
  font-weight: bold;
}

.receipt-modal-header h2 {
  margin: 0;
  color: #f1f1f1;
  text-align: center;
}

.receipt-modal-header .close-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.receipt-modal-header .close-button:hover {
  background-color: #c0392b;
}

/* === Cuerpo del Modal === */
.receipt-modal-body {
  padding: 20px;
  background-color: #f9f9f9;
  flex: 1;
  overflow-y: auto;
}

.receipt-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.receipt-info p {
  margin: 5px 0;
  font-size: 14px;
  color: #333;
}

.establishment-section {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #fefefe;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.establishment-section div {
  flex: 1;
}

.establishment-section p {
  margin: 5px 0;
  font-size: 14px;
}

/* === Tabla de Conceptos a Pagar === */
.concepts-to-pay {
  margin-top: 20px;
  border: 1px solid #4CAF50;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.concepts-to-pay h3 {
  text-align: center;
  margin: 10px 0;
  color: #333;
}

.concepts-to-pay table {
  width: 100%;
  border-collapse: collapse;
}

.concepts-to-pay th,
.concepts-to-pay td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

.concepts-to-pay th {
  background-color: #4CAF50;
  color: white;
  font-weight: bold;
}

.concepts-to-pay tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.concepts-to-pay tbody tr:hover {
  background-color: #e9e9e9;
}

/* === Nota Importante === */
.important-note {
  margin-top: 20px;
  padding: 10px;
  background-color: #ffebee;
  color: #c62828;
  border: 2px solid #e57373;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
}

/* === Botones del Footer === */
.receipt-modal-footer {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 15px;
  background-color: #f1f1f1;
  border-radius: 0 0 8px 8px;
}

.download-button {
  background-color: #ffffff !important;
  color: #388E3C !important;
  font-size: 24px !important;
  font-weight: bold;
  border: 1px solid #388E3C !important;
  border-radius: 5px !important;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-button img {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.download-button:hover {
  background-color: #388E3C;
}

.close-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.close-button:hover {
  background-color: #c0392b;
}
