.faq-section {
    padding: 4rem 1rem;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .faqcontainer {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .section-heading {
    font-size: 2.5rem;
    color: #003366;
    margin-bottom: 1rem;
  }
  
  .section-heading .highlight {
    color: #3F51B5;
  }
  
  .section-description {
    font-size: 1.25rem;
    color: #555555;
    margin-bottom: 2rem;
  }
  
  .faq-list {
    text-align: left;
  }
  
  .faq-item {
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    margin-bottom: 1rem;
    overflow: hidden;
  }
  
  .faq-question {
    font-size: 1.2rem;
    padding: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333333;
    font-weight: bold;
  }
  
  .faq-question:hover {
    background-color: #f1f1f1;
  }
  
  .faq-icon {
    font-size: 1.5rem;
    color: #6c63ff;
  }
  
  .faq-answer {
    font-size: 1rem;
    color: #555555;
    padding: 1rem;
    background-color: #f9f9f9;
  }
  