/* Fondo del modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Contenedor del modal */
.custom-form-modal-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 600px;
  max-height: 90vh;
  overflow-y: scroll;
  margin-top: 3rem;
  border: 2px solid #4CAF50; /* Borde verde */
}

/* Scrollbar personalizada */
.custom-form-modal-content::-webkit-scrollbar {
  width: 8px;
}

.custom-form-modal-content::-webkit-scrollbar-thumb {
  background-color: #bbb;
  border-radius: 4px;
}

.custom-form-modal-content::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

/* Título del modal */
.custom-form-modal-content h2 {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #333;
}

/* Contenedor de campos */
.fields-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Estilo de cada campo */
.field-item {
  border-bottom: 1px solid #ddd;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  background-color: #f6eced;
  border-radius: 8px;
  padding: 10px;
}

.field-item label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #555;
}

.field-item input,
.field-item select {
  width: 95%;
  padding: 0.5rem;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  margin-left: 5px;
  margin-right: 5px;
}

.field-item input[type="text"],
.field-item select {
  height: 2.5rem;
}

.field-item input[type="checkbox"] {
  width: auto;
  margin-left: 5px;
}

/* Botones para agregar y eliminar opciones */
.options-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.options-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  border: 1.5px solid black;
  border-radius: 5px;
  padding: 5px;
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Transición suave para el escalado y sombra */
}

.options-list li:hover {
  transform: scale(1.01); /* Incrementa ligeramente el tamaño */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra alrededor del elemento */
  background-color: #f9f9f9; /* Cambia ligeramente el fondo para resaltarlo (opcional) */
}
.add-option-button,
.delete-option-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.3rem 0.7rem;
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 4px;
}

.delete-option-button {
  background-color: #dc3545;
}

.add-option-button:hover {
  background-color: #0056b3;
}

.delete-option-button:hover {
  background-color: #c82333;
}

/* Botón para eliminar campo */
.delete-field-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 0.5rem;
}

.delete-field-button:hover {
  background-color: #c82333;
}

/* Botón para agregar campos */
.add-field-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 1rem;
  width: 100%;
}

.add-field-button:hover {
  background-color: #0056b3;
}

/* Botones de acción del modal */
.modal-buttons {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
}

.primary-button {
  background-color: #28a745 !important;
  border: none !important;
  color: white !important;
  padding: 0.5rem 1rem !important;
  font-size: 1rem !important;
  cursor: pointer !important;
  border-radius: 5px !important; 
  transition: background-color 0.3s ease !important;
}

.primary-button:hover {
  background-color: #00711a !important;
}

.secondary-button {
  background-color: #6c757d !important;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1rem !important;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.secondary-button:hover {
  background-color: #5a6268 !important;
}

/* Opcional: Espaciado entre elementos */
input[type="text"],
select {
  margin-bottom: 1rem;
}

/* Opciones con input */
input[type="checkbox"] {
  margin-left: 10px;
}