/* VerifyEmail.css */

.verify-email-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40rem;
  background-image: url("../../public/newLoginBackground.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.verify-email-card {
  background-color: #fff;
  padding: 2rem 3rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.verify-email-card input {
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.verify-email-card button {
  background-color: #f5f5dc;
  color: #333;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
}

.verify-email-card button:hover {
  background-color: #e6e6d4;
}

.verify-email-card p {
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: #666;
}

.error {
  font-size: 0.85rem;
  color: red;
  margin-bottom: 1rem;
  text-align: center;
}

.resend-button{
  background-color: #b7dbfc !important;
  color: #333;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}

input{
  margin-top: 5px;
  margin-bottom: 5px;
}