.contact-us {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
  background-color: #f9f9f9;
  margin-top: 80px;
  padding-bottom: 50px;
}

.contact-us-container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.contact-us-content {
  flex: 1;
}

.contact-heading {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.contact-heading span {
  color: #6c63ff;
}

.contact-description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1.5rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-input,
.contact-textarea {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  font-family: "Arial", sans-serif;
  color: #333;
}

.contact-input:focus,
.contact-textarea:focus {
  outline: none;
  border-color: #6c63ff;
}

.contact-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  background-color: #6c63ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
}

.contact-button:hover {
  background-color: #5848d0;
}

.contact-us-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-us-image img {
  max-width: 100%;
  height: auto;
}

/* === MEDIA QUERIES === */

/* Para pantallas medianas (tablets) */
@media screen and (max-width: 768px) {
  .contact-us-container {
    flex-direction: column;
    gap: 1.5rem;
  }

  .contact-heading {
    font-size: 1.8rem;
    text-align: center;
  }

  .contact-description {
    text-align: center;
  }

  .contact-form {
    align-items: center;
  }

  .contact-input,
  .contact-textarea {
    max-width: 100%;
  }

  .contact-button {
    width: 100%;
  }

  .contact-us-image {
    margin-top: 1.5rem;
  }
}

/* Para pantallas pequeñas (móviles) */
@media screen and (max-width: 480px) {
  .contact-heading {
    font-size: 1.5rem;
  }

  .contact-description {
    font-size: 0.9rem;
  }

  .contact-input,
  .contact-textarea {
    font-size: 0.9rem;
    padding: 0.6rem;
  }
  

  .contact-button {
    padding: 0.6rem;
    font-size: 0.9rem;
  }
}
