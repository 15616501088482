.forms-list-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.forms-table {
    margin-top: 0.5rem;
    width: 100%;
    border-collapse: collapse;
}

.forms-table th,
.forms-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.forms-table th {
    background-color: #f2f2f2;
    color: #333;
}

.forms-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.forms-table tr:hover {
    background-color: #f1f1f1;
}

.forms-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #4CAF50;
    color: white;
}

.forms-table button {
    background: none;
    border: none;
    cursor: pointer;
    margin-top: 0 !important;
}
  
.forms-table button img {
    width: 20px;
    height: 20px;
}

.form-row {
    cursor: pointer;
}

.form-row:hover {
    background-color: #f0f0f0;
}

.forms-table td {
    vertical-align: middle;
    /* Para asegurar que el contenido esté centrado verticalmente */
}
