.forms-list-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    background-color: #e8e8e8f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 100%;
    max-width: fit-content;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
}

.forms-table {
    margin-top: 1rem;
    width: 100%;
    border-collapse: collapse;
    font-size: 0.95rem;
    font-weight: bold;
    color: #333;
}

.forms-table th,
.forms-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.forms-table th {
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #4CAF50;
}

.forms-table td {
    background-color: #f7f7f7;
    text-align: center;
}

.forms-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.forms-table tr:hover {
    background-color: #e6e6e6;
}

.form-row {
    cursor: pointer;
}

.form-row:hover {
    background-color: #e0e0e0;
}

.back-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
    font-size: 1rem;
    text-transform: uppercase;
}

.back-button:hover {
    background-color: #45a049;
}

.download-button {
    background-color: #2196F3;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
    font-size: 1rem;
    text-transform: uppercase;
    margin-left: 10px;
}

.download-button:hover {
    background-color: #1976D2;
}

.form-fields {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
    border: 1px solid #ddd;
}

.form-fields th,
.form-fields td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
}

.form-fields th {
    background-color: #f5f5f5;
    font-weight: bold;
    text-transform: uppercase;
}

.form-fields td {
    background-color: #fff;
}

.form-fields td:nth-child(2) {
    font-weight: bold;
    color: #333;
}

h2 {
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #3f51b5;
}

p {
    font-size: 1rem;
    color: #0f0e0e;
    margin-bottom: 10px;
}

/* Espaciado entre los botones */
.button-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}
