.add-button {
    display: table-cell;
    align-content: center;
    position: center;
    padding: 0.5rem 1rem;
    width: 100%;
    max-width: fit-content;
    font-size: 1rem;
    color: #ffffff;
    font-weight: bold;
    background-color: rgb(56, 193, 56);
    border: 2px solid #118100;
    border-radius: 7px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-button:hover {
    background-color: #ffffff;
    color: rgb(56, 193, 56);
    border-color: rgb(56, 193, 56);
}

.plus-icon {
    margin-top: 2rem;
    font-size: 2rem;
    margin-right: 0.5rem;
}

.visits-list{
    background-color: rgba(255, 255, 255, 0.957);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 20px;
    max-width: 450px;
}

.pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .pagination button {
    padding: 5px 10px;
    margin: 0 5px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s ease;
  }
  
  .pagination button:hover {
    background-color: #0056b3;
  }
  
  .pagination button.active {
    background-color: #0056b3;
    font-weight: bold;
  }

  /* Contenedor principal */
.visits-list-container {
    margin: 20px auto;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    width: 40rem; /* Ajusta el ancho según tus necesidades */
    height: 21.5rem;
  }
  
  /* Tabla estilizada */
  .styled-table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
  }
  
  .styled-table th,
  .styled-table td {
    max-width: 15px !important;
    padding: 2px;
    text-align: center !important;
    border-bottom: 1px solid #ddd;
    align-items: center !important;
  }
  
  .styled-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .styled-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .styled-table td img {
    cursor: pointer;
  }
  
  .styled-table td {
    vertical-align: middle;
  }
  
  .styled-table td:first-child {
    padding-left: 15px;
  }
  
  /* Paginación */
  .pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .pagination button {
    padding: 5px 10px;
    margin: 0 5px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s ease;
  }
  
  .pagination button:hover {
    background-color: #0056b3;
  }
  
  .pagination button.active {
    background-color: #0056b3;
    font-weight: bold;
  }
  
  .view-button {
    padding: 5px 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .view-button:hover, .delete-button:hover, .invoice-button:hover {
    background-color: #71a7fe;
  }

  .view-button,.delete-button, .invoice-button{
    background: none;
    margin-top: 5px;
    margin-left: 5px;
    padding: 5px 5px;
    max-height: 30px;
  }

  /* Estilos para el contenedor de los botones de acción */
.action-buttons {
  display: flex;
  justify-content: center; /* Opcional: centra los botones horizontalmente dentro del td */
  align-items: center;     /* Alinea los botones verticalmente */
  gap: 5px;                /* Espacio entre los botones */
}