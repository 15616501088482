/* Estilos generales para la sección de bienvenida */
.welcome-section {
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 1rem;
  background-color: #ffffff;
}

.container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  gap: 2rem;
}

.text-content {
  flex: 1;
  max-width: 600px;
}

.text-content h1 {
  font-size: 2.5rem;
  color: #003366;
  margin-bottom: 1rem;
}

.text-content .highlight {
  color: #3F51B5;
}

.text-content p {
  font-size: 1.25rem;
  color: #555555;
  margin-bottom: 2rem;
}

.cta-button {
  background-color: #3F51B5;
  color: #ffffff;
  padding: 0.75rem 2rem;
  font-size: 1.25rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /* Asegura que el cursor sea siempre pointer */
  pointer-events: auto;
  /* Habilita siempre la interacción */
  transition: background-color 0.3s ease, transform 0.2s ease;
  z-index: 2;
  /* Asegura que el botón esté por encima de otros elementos */
  position: relative;
  /* Para aplicar el z-index correctamente */
  text-decoration: none; /* Elimina el subrayado */
}

.cta-button:hover {
  background-color: #4a4ad4;
  transform: scale(1.05);
  /* Mejora la experiencia visual */
  opacity: 0.9;
}

.image-content {
  flex: 1;
  max-width: 600px;
}

.image-content img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .welcome-section-container {
    flex-direction: column !important;  /* Colocar elementos verticalmente */
    width: 100%;
  }
}