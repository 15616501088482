/* Fondo oscuro */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

}

/* Contenedor del modal */
.modal-container {
    background: white;
    width: 80%;
    /* Ajusta según sea necesario */
    max-width: 60rem;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 40rem !important;
    padding: 5px !important;
    z-index: 1001;
    position: relative;
}

/* Encabezado */
.modal-header {
    padding: 15px;
    background: #0056b3;
    /* Color azul */
    color: white !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-header h2 {
    color: white !important;
}

/* Botón de cerrar */
.close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.close-button:hover {
    color: black;
}

/* Cuerpo */
.modal-body {
    padding: 15px;
    /* Limita la altura del contenido */
    overflow-y: auto;
    margin-bottom: .8rem;
    border-radius: 8px;
}

/* Tabla */
.history-table {
    width: 100%;
    border-collapse: collapse;
}

.history-table th,
.history-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.history-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

/* Footer */
.modal-footer {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    background: #f9f9f9;
}