.forgot-password-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.forgot-password-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../public/newLoginBackground.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.forgot-password-card {
  background-color: #fff;
  padding: 2rem 3rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.forgot-password-card input {
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.forgot-password-card button {
  background-color: #f5f5dc;
  color: #333;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  margin-top: 10px;
}

.forgot-password-card button:hover {
  background-color: #e6e6d4;
}

.forgot-password-card p {
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: #666;
}