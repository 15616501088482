/* === Overlay del Modal === */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* === Contenido del Modal === */
.optimized-routes-modal {
  background-color: #ffffff;
  border-radius: 8px;
  width: 500px;
  overflow-y: auto;
  border: 2px solid #28a745; /* Borde verde */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  height: 90%;
  margin-top: 4rem;
}

/* === Encabezado del Modal === */
.optimized-routes-header {
  background-color: #4CAF50 !important;
  color: white;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  border-radius: 8px 8px 0 0;
}

.optimized-routes-header p {
  font-size: 0.9rem;
  margin: 5px 0 0 0;
}

/* === Botón de Cerrar === */

.google-maps-button, 
.suggested-routes-close-modal-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #4c7caf;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px !important;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.google-maps-button:hover {
  background-color: #45a049;
}

.suggested-routes-close-modal-btn {
  background-color: #e74c3c;
}

.suggested-routes-close-modal-btn:hover {
  background-color: #c0392b;
}

.button-icon {
  width: 24px;
  height: 24px;
  margin-right: 5px; /* Espacio entre icono y texto */
}

/* === Lista de Rutas === */
.route-list {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}

.route-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.route-index {
  background-color: #4CAF50;
  color: white;
  font-weight: bold;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.route-details {
  flex: 1;
  margin-left: 10px;
}

.route-details h4 {
  margin: 0;
  font-size: 1rem;
  color: #333;
}

.route-details p {
  margin: 0;
  font-size: 0.85rem;
  color: #777;
}

/* === Estado de la Ruta === */
.route-status {
  font-size: 0.9rem;
  font-weight: bold;
}

.route-status.inicio {
  color: #28a745;
}

.route-status.en-ruta {
  color: #f39c12;
}

.route-status.final {
  color: #e74c3c;
}

/* === Mensaje de Error === */
.error-message {
  background-color: #000;
  color: #fff;
  font-size: 0.9rem;
  padding: 10px;
  text-align: center;
  margin-top: 10px;
  border-radius: 4px;
}

/* === Layout Principal === */
.suggested-routes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.content {
  max-width: 800px;
  width: 100%;
  margin-top: 4rem;
}

.section-container {
  margin-top: 20px;
  padding: 15px;
  background-color: #f4f6f8;
  border: 1px solid #ddd;
  border-radius: 8px;
}

/* === Map Section === */
.map-section {
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.map-section p {
  font-size: 14px;
  color: #666;
  padding: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

/* === Visit Items === */
.visit-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 10px;
  margin-top: 10px;
}

.visit-item p {
  margin: 0;
  font-size: 14px;
}

.no-visits {
  font-size: 14px;
  color: #888;
  padding: 10px;
  text-align: center;
}

/* === Botones === */
.button-group {
  display: flex;
  gap: 5px;
}

.add-to-route-btn,
.remove-from-route-btn,
.map-btn {
  padding: 5px 10px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 12px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.add-to-route-btn {
  background-color: #4CAF50;
}

.add-to-route-btn:disabled,
.map-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.remove-from-route-btn {
  background-color: #e74c3c;
  margin-left: 10px;
}

.remove-from-route-btn:hover {
  background-color: #c0392b;
}

.map-btn {
  background-color: #8cc2fb;
}

.map-btn:hover {
  background-color: #6aaed6;
}

.contact-btn,
.working-hours-btn {
  background-color: #8cc2fb;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
}

.contact-btn:hover,
.working-hours-btn:hover {
  background-color: #6aaed6;
}

.contact-btn svg,
.working-hours-btn svg {
  color: #fff;
  transition: color 0.3s;
}

.contact-btn:hover svg {
  color: #4CAF50;
}

/* === Tooltip de advertencia === */
.tooltip-warning {
  color: #d9534f;
  background-color: #f9eaea;
  padding: 10px;
  border-radius: 4px;
  font-size: 0.9em;
  margin-bottom: 15px;
  text-align: center;
  border: 1px solid #f5c6cb;
}

/* === Modal Overlay General === */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* === Modales === */
.suggested-routes-modal {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  width: 600px;
  border: 2px solid #17a2b8;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 90%;
  margin-top: 3rem;
}

.suggested-routes-modal h3 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 10px;
}

.suggested-routes-modal p {
  font-size: 1rem;
  color: #555;
}

.suggested-routes-modal .close-button {
  align-self: flex-end;
  padding: 8px 12px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.suggested-routes-modal .close-button:hover {
  background-color: #c82333;
}

.suggested-routes-modal button {
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.suggested-routes-modal button:hover {
  background-color: #218838;
}

/* === Contenedor de Rutas Optimizadas === */
.optimized-route-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
}

.route-item {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.route-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.route-index {
  background-color: #4CAF50;
  color: white;
  font-size: 18px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.route-details h4 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.route-details p {
  margin: 5px 0 0 0;
  font-size: 14px;
  color: #777;
}

.route-status {
  font-size: 14px;
  font-weight: bold;
}

.route-status .start-point {
  color: #4CAF50;
}

.route-status .end-point {
  color: #e74c3c;
}

.route-status .in-route {
  color: #f39c12;
}

.button-icon {
  width: 24px;
  height: 24px;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
}

/* === Información adicional === */
.info-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px dashed #ddd;
  border-radius: 8px;
  margin-top: 10px;
  background-color: #f9f9f9;
}

.info-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.info-text p {
  margin: 0;
}

.click-instruction {
  color: #e74c3c;
}


iframe{
  border-radius: 8px;
}