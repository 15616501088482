.login-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Asegura que ocupe toda la altura de la ventana */
}

.login-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../public/newLoginBackground.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

.login-card {
  background-color: #fff;
  padding: 2rem 3rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  margin-top: 4rem;
  text-align: center;
}


.login-card input {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem; /* Añadir un padding derecho para el icono */
  margin-bottom: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
}

.login-card input.input-error {
  border-color: red;
}

.login-card button {
  background-color: #f5f5dc;
  color: #333;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%; /* Asegura que el botón tenga el mismo ancho que los inputs */
  box-sizing: border-box; /* Esto asegura que el padding se incluya en el ancho total */
}

.login-card button:hover {
  background-color: #e6e6d4;
}

.login-card p {
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: #666;
}

.login-card p a {
  color: #333;
  text-decoration: none;
  font-weight: bold;
}

.login-card p a:hover {
  text-decoration: underline;
}

.login-card .error {
  color: red;
  font-size: 0.85rem;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.password-container {
  position: relative;
}

.password-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.password-wrapper input {
  flex: 1;
  padding-right: 2.5rem; /* Asegura espacio suficiente para el icono */
  box-sizing: border-box;
}

.password-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  top: 50%;
  transform: translateY(-100%);
}
