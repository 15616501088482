/* === Overlay del Modal === */
.periodic-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10020;
  overflow: hidden;
}

/* === Contenedor Principal del Modal === */
.periodic-visit-modal-container {
  background-color: #ffffff;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  border: 2px solid #4CAF50;
  position: relative;
}

/* === Encabezado del Modal === */
.periodic-visit-header {
  background-color: #4CAF50;
  color: white;
  padding: 15px;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  border-radius: 8px 8px 0 0;
  position: relative;
}

.periodic-visit-header h2 {
  margin: 0;
  color: #f1f1f1;
}

.periodic-visit-header .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 8px;
  width: 30px;
  height: 30px;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.periodic-visit-header .close-button:hover {
  background-color: #c0392b;
}

/* === Cuerpo del Modal === */
.periodic-visit-body {
  padding: 20px;
  background-color: #fbe8cd;
  flex: 1;
  overflow-y: auto;
}

.periodic-visit-body p {
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

.periodic-visit-body a {
  color: #007bff;
  text-decoration: underline;
  display: block;
  text-align: center;
  margin: 10px 0;
}

.periodic-visit-body a:hover {
  color: #0056b3;
}

/* === Sección de Selección de Meses === */
.months-container h3 {
  text-align: center;
  margin-bottom: 10px;
  color: #333;
}

.months-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.months-grid button {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  color: #555;
  transition: all 0.3s ease;
}

.months-grid button.selected {
  background-color: #4CAF50;
  color: white;
  border: 1px solid #388E3C;
}

.months-grid button:hover {
  background-color: #81C784;
  color: white;
}

/* === Configuración de Día y Notificación === */
.date-notification-container {
  display: flex;
  flex-direction: row !important;
  gap: 1rem;
  justify-content: space-between !important;
  margin-top: 1.5rem; 
}

.date-notification-container label {
  font-weight: bold;
  color: #555;
  margin-bottom: 0.5rem;
}

select {
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

select:focus {
  border-color: #4CAF50;
}

/* === Footer del Modal === */
.modal-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 15px;
  background-color: #f1f1f1;
  border-radius: 0 0 8px 8px;
  margin-top: 0% !important;
}

.modal-actions button {
  font-size: 1rem;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-actions button:first-child {
  background-color: #4CAF50;
  color: white;
}

.modal-actions button:first-child:hover {
  background-color: #388E3C;
}

.modal-actions button:last-child {
  background-color: #e74c3c;
  color: white;
}

.modal-actions button:last-child:hover {
  background-color: #c0392b;
}

/* === Responsive === */
@media (max-width: 768px) {
  .months-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .periodic-visit-header {
    font-size: 1.1rem;
  }
}
