/* Sidebar.css */
.sidebar {
  width: 260px;
  transition: width 0.3s ease;
  background-color: #9ad2f7;
  color: rgb(3, 0, 0);
  padding: 1rem;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 60px; /* Debajo del header */
  z-index: 100; /* Asegura que esté encima del contenido */
}

.sidebar.collapsed {
  width: 100px !important;
  z-index: 101 !important; /* Asegura que esté encima del contenido */
}

/* Asegura que el contenido se mueva según el estado del sidebar */
.dashboard-content, .profile-content  {
  margin-left: 260px; /* Espacio para el sidebar expandido */
  transition: margin-left 0.3s ease;
}

.sidebar.collapsed ~ .dashboard-content {
  margin-left: 50px; /* Ajusta el contenido cuando el sidebar está colapsado */
}

/* Ajustes adicionales */
.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
}

.sidebar ul li:hover {
  background-color: #f0f0f0;
}

.sidebar ul li img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  flex-shrink: 0;
}

.sidebar ul li span {
  font-size: 1rem;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.sidebar.collapsed ul li span {
  opacity: 0;
}

.toggle-btn {
  background: none;
  border: none;
  cursor: pointer;
  margin-bottom: 2rem;
  padding: 0;
  width: 35px;
  height: 35px;
  position: relative;
  z-index: 101;
  top: 0; /* Asegura que el botón esté alineado correctamente */
}

.toggle-btn img {
  width: 20px;
  height: 20px;
}

.dashboard-container {
  display: flex;
  margin-top: 1rem; /* Para asegurarte de que todo el contenido esté también debajo del header */
}

.long-span:hover span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-size: smaller;
}

