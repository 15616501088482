.pricing-plans {
  padding: 4rem 1rem;
  background-color: #f9f9f9;
  text-align: center;
}

.pricing-container {
  max-width: 1200px;
  margin: 0 auto;
}

.section-heading {
  font-size: 2.5rem;
  color: #003366 !important;
  margin-bottom: 1rem;
}

.section-heading span {
  color: #3F51B5;
}

.section-description {
  font-size: 1.25rem;
  color: #555555;
  margin-bottom: 3rem;
}

.plans-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dos columnas */
  gap: 2rem;
  justify-content: center;
  margin-top: 2rem;
}


@media (max-width: 768px) {
  .plans-grid {
    grid-template-columns: 1fr; /* Una columna en dispositivos pequeños */
  }
}

.plan-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  transition: transform 0.3s ease;
  height: 100%;
  z-index: 1; /* Asegúrate de que el botón no quede tapado */
}

.plan-card hr {
  border: none;
  border-top: 1px solid #000000;
  margin: 1.5rem 0;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.plan-card:hover {
  transform: translateY(-5px);
}

.featured {
  background: linear-gradient(135deg, #6c63ff 0%, #845ec2 100%);
  color: #ffffff !important;
}

.featured p {
  color: #ffffff !important;
}

.featured li {
  color: #ffffff !important;
}

.featured .subscribe-button {
  background-color: #ffffff !important;
  color: #6c63ff;
}

.featured-tag {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f56565;
  color: #ffffff;
  padding: 0.25rem 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  border-radius: 5px;
}

.plan-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 1rem;
}

.plan-price {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #003366;
}

.plan-duration {
  font-size: 1rem;
  color: #555555;
  margin-bottom: 1.5rem;
}

.plan-features {
  text-align: left;
  margin-bottom: 2rem;
}

.plan-features .main-feature {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.plan-features ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.plan-features ul li {
  font-size: 1rem;
  color: #555555;
  margin-bottom: 0.5rem;
}

.subscribe-button {
  font-size: 1.2rem;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer !important; /* Asegura que el cursor sea siempre pointer */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.subscribe-button:hover {
  opacity: 0.9;
  cursor: pointer !important; /* Asegura que el cursor sea siempre pointer */
  transform: scale(1.05); /* Pequeña animación para mejorar la interacción */
}

#pricing {
  scroll-margin-top: 70px;
  /* Ajusta este valor según la altura de tu header */
}