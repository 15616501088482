/* General */
.about-us {
  margin-top: 7rem;
  background-color: #f9f9f9;
}

.about-us-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Encabezado */
.about-us-section-heading {
  font-size: 3rem;
  background: linear-gradient(to right, #6c63ff, #3f3dff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 1.5rem;
}

.section-description {
  font-size: 1.2rem;
  text-align: center;
  color: #555555;
  margin-bottom: 3rem;
}

/* Historia */
.history {
  padding: 2rem;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 3rem;
}

/* Proyecto BioSafeApp */
.featured-project {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  background: linear-gradient(135deg, #a49ffe 0%, #845ec2 100%);
  border-radius: 10px;
  color: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.featured-image {
  max-width: 250px;
  border-radius: 50%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Servicios */
.services {
  margin-top: 3rem;
  background-color: #d9d6d6;
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 3rem;
  align-content: center;
  justify-content: center;
}

.services ul {
  list-style: none;
  padding: 0;
  
}

.services ul li {
  font-size: 1rem;
  color: #333333;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  position: relative;
}

.services ul li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #6c63ff;
  font-weight: bold;
}

/* Equipo */
.team {
  padding: 2rem;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 3rem;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
}

.team-member {
  text-align: center;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: transform 0.3s ease;
}

.team-member:hover {
  transform: translateY(-5px);
}

.team-member img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.team-member h4 {
  font-size: 1.2rem;
  color: #333333;
}

.team-member p {
  font-size: 1rem;
  color: #555555;
}

/* CTA */
.cta {
  text-align: center;
  padding: 2rem;
  background: linear-gradient(90deg, #cfcdf8, #918fff);
  border-radius: 10px;
  color: #ffffff;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.cta p {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.about-us-a {
  background-color: #ffffff;
  color: #6c63ff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 50px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.about-us-a:hover {
  transform: translateY(-3px);
  background-color: #e6e6ff;
}
/* === Contenedor Principal === */
.about-us-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin-top: -4rem !important;
}

.about-us-section-heading {
  font-size: 2rem;
  text-align: center;
  color: #333;
}

.highlight {
  color: #4CAF50;
}

.section-description {
  text-align: center;
  font-size: 1rem;
  color: #555;
  margin-bottom: 1rem;
}

/* === Historia === */
.history {
  background: #f9f9f9;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* === Proyecto Destacado === */
.featured-project {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
}

.featured-project img.featured-image {
  max-width: 100px;
  margin-bottom: 1rem;
}

.featured-project h3 {
  margin-bottom: 0.5rem;
}

.featured-project p {
  font-size: 1rem;
  line-height: 1.5;
}

/* === Servicios === */
.services {
  background: #e8eaf6;
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
}

.services ul {
  list-style: none;
  padding: 0;
  margin: 1rem 0 0;
}

.services li {
  font-size: 1rem;
  margin: 0.5rem 0;
  color: #333;
}

/* === Equipo === */
.team {
  text-align: center;
}

.team-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.team-member {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  text-align: center;
  width: calc(50% - 1rem);
}

.team-member img {
  max-width: 80px;
  margin-bottom: 0.5rem;
  border-radius: 50%;
  object-fit: cover;
}

.team-member h4 {
  font-size: 1rem;
  margin: 0.5rem 0;
  color: #333;
}

.team-member p {
  font-size: 0.9rem;
  color: #666;
}

/* === CTA === */
.cta {
  background: #d1c4e9;
  padding: 1.5rem;
  text-align: center;
  border-radius: 8px;
  color: #333;
}

.cta a.about-us-a {
  display: inline-block;
  background-color: #4CAF50;
  color: #fff;
  padding: 0.8rem 1.5rem;
  text-decoration: none;
  border-radius: 8px;
  font-weight: bold;
  margin-top: 1rem;
}

.cta a.about-us-a:hover {
  background-color: #388e3c;
}

/* === Responsive para móviles === */
@media screen and (max-width: 768px) {
  .about-us-section-heading {
      font-size: 1.5rem;
  }

  .container {
      margin-top: 0% !important;
  }
  .services,
  .history,
  .featured-project {
      padding: 1rem;
  }

  .team-grid {
      gap: 1rem;
  }

  .team-member {
      width: 100%;
  }

  .cta {
      padding: 1rem;
  }

  .cta a.about-us-a {
      width: 100%;
      text-align: center;
  }
}
