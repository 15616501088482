.establishment-form-container {
  margin-top: 4rem;
  max-height: 90vh;
  width: 42 vw !important;
  overflow-y: auto;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid #4CAF50; /* Borde verde */
}

.establishment-form {
  margin-top: 1rem !important;
  height: fill-available;
  width: 40rem;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f9f9f9;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.establishment-form div {
  margin-bottom: 1rem;
}

.establishment-form label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.establishment-form input,
.establishment-form select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  text-align: center;
  /* Asegura que el padding y el borde no afecten el ancho total */
}

.establishment-form .input-error {
  border-color: red;
}

.establishment-form .error-text {
  color: red;
  font-size: 0.875rem;
}

.establishment-form button {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.establishment-form button:hover {
  background-color: #45a049;
}

.suggestion-bubble {
  background-color: #b5baf6 !important;
  margin: 5px;
  color: black !important;
  border-radius: 5px;
}


.suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.suggestion-item {
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f9f9f9;
}

.suggestion-item:hover {
  background-color: #e0e0e0;
}

.suggestion-item strong {
  display: block;
  color: #333;
  font-size: 14px;
}

.suggestion-item small {
  color: #666;
  font-size: 12px;
}

.suggestions-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.suggestion-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  background-color: #f9f9f9;
  width: 100%;
  margin: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #f9e0c9;
}

.suggestion-card:hover {
  background-color: #e6e6e6;
}

.suggestion-card p {
  margin: 4px 0;
  font-size: 14px;
}