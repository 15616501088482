/* === Overlay del Modal === */
.plan-expired-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6); /* Fondo semitransparente oscuro */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* === Contenedor Principal del Modal === */
.plan-expired-modal-container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  max-width: 500px;
  width: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 2px solid #4CAF50;
}

/* === Encabezado === */
.plan-expired-header {
  background-color: #4CAF50;
  color: #ffffff;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.plan-expired-header h2 {
  color: #ffffff;
  
}

/* === Cuerpo del Modal === */
.plan-expired-body {
  padding: 1.5rem;
  font-size: 1rem;
  color: #333;
  line-height: 1.5;
}

/* === Footer del Modal === */
.plan-expired-footer {
  padding: 1.5rem;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.plan-expired-button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 0.8rem 1.2rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.plan-expired-button:hover {
  background-color: #0056b3;
}

.thanks-message {
  color: #555;
  font-size: 0.9rem;
  line-height: 1.4;
}
