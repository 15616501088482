.dashboard-container {
  display: flex;
  padding-top: 60px;
  /* Ajusta para que el contenido quede debajo del header */
}

.welcome-message {
  border: 2px dashed #333;
  padding: 1rem;
  border-radius: 10px;
  background-color: #f9f9f9;
  width: fit-content;
  margin-left: 1.5rem;
}

.filters-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.filters-container input,
.filters-container select {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.search-container input {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.search-container button {
  background: rgb(197, 238, 247);
  border: 1px solid blue;
  padding: 0.3rem;
  cursor: pointer;
}

.search-container button:hover {
  background: rgb(255, 255, 255);
}

.search-icon {
  width: 20px;
  height: 20px;
}

.dashboard-content {
  flex-grow: 1;
  padding: 2rem;
  transition: margin-left 0.3s ease;
  /* Movimiento suave del contenido */
  margin-left: 210px;
  /* Espacio para el sidebar expandido */
  padding-top: 0 !important;
}

.sidebar.collapsed~.dashboard-content {
  margin-left: 4rem;
  /* Ajusta el contenido cuando el sidebar está colapsado */
}

/* NUEVAS CLASES */

.dashboard-main {
  display: flex;
  justify-content: flex-start;
  /* Espaciado entre el calendario y las visitas */
  /*centrar el contenido */
  align-items: t;
  gap: 0px !important;
}

.calendar-section {
  width: 60%;
  /* 60% para el calendario */
  margin-right: 0;
  align-items: center !important;
}

.calendar-section a {
  color:#333;
  text-decoration: none;
}

.total-visits-section,
.total-establishments-section {
  width: calc(50% - 10px); /* Ambos ocupan el 50% del ancho del contenedor */
  height: 12rem;
  background-color: #ccd3f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 5px;
}

.total-establishments-section {
  background-color: #dff9cc;
}

.timeframe-selector {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.timeframe-selector label {
  display: flex;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
}

.timeframe-selector input {
  margin-right: 0.5rem;
}

.total-visits-display {
  text-align: center;
}

.total-visits-display h1 {
  font-size: 48px;
  margin: 0;
}

.total-visits-display p {
  font-size: 18px;
  color: #555;
}

/* Botón de Ver Total de Visitas */
.view-total-visits-button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
}

.view-total-visits-button:hover {
  background-color: #0056b3;
}

.total-establishments-section h1 {
  font-size: 48px;
  margin: 0;
  text-align: center;
}

.total-establishments-section p {
  font-size: 18px;
  color: #555;
}

.dashboard-card {
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
  text-align: center;
}

.category-piechart-section {
  width: 35rem; /* El gráfico ocupa todo el ancho */
  height: fit-content;
  max-height: 30rem;
  background-color: #f5f5f5;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;  
}

.info-cards-section {
  display: flex;
  justify-content: space-between; /* Coloca los dos modales uno al lado del otro */
  width: 100%; /* Asegura que ocupe todo el ancho */
}

.info-cards-wrapper {
  display: flex;
  flex-direction: column; /* Organiza el contenido en columnas */
  gap: 0px;
  width: 40rem;
}

/* Estilo para el botón "Ver Rutas Sugeridas para Hoy" */
.suggested-routes-button {
  background-color: #007bff; /* Azul más vivo */
  color: white;
  font-size: 1.2rem;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Transiciones suaves */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra para el efecto de elevación */
  width: 100%; /* Ocupa el ancho completo */
  max-width: 700px; /* Tamaño máximo para que no sea demasiado grande */
  margin: 20px auto; /* Centra el botón horizontalmente */
}

.suggested-routes-button:hover {
  background-color: #0056b3; /* Color más oscuro en hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Aumenta la sombra al pasar el mouse */
}

.suggested-routes-button:active {
  transform: translateY(2px); /* Efecto de pulsado */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); /* Disminuye la sombra cuando está activo */
}

.fc-day-today {
  background-color: #6fbaf4 !important;
}


.fc-day-today a{
  justify-self: center !important;
  align-self: center !important;
  margin: 0 auto;
  padding-bottom: 0px !important;
}
.fc-daygrid-day-number{
  justify-self: center !important;
  align-self: center !important;
  margin: 0 auto;
  padding-bottom: 0px !important;
  font-weight: 500;
}

.fc-event-title.fc-sticky {
  white-space: normal; /* Permite que el texto haga un wrap en múltiples líneas */
  word-wrap: break-word; /* Asegura que palabras largas se partan si es necesario */
  overflow-wrap: break-word; /* Asegura que las palabras largas no desborden */
}